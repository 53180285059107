import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes, 
  useNavigate, 
  useLocation 
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import WordCard from './components/WordCard';
import UserProgress from './components/UserProgress';
import Menu from './components/Menu';
import Rules from './components/Rules';
import CelebrationScreen from './components/CelebrationScreen';
import RulesPopup from './components/RulesPopup';
import StartScreen from './components/StartScreen';
import EGESection from './components/EGESection';
import { words as initialWords } from './data/words';
import './App.css';


const CORRECT_REPEAT_WINDOW = 20;
const INCORRECT_REPEAT_WINDOW = 15;
const STORAGE_KEY = 'nnn-training-progress';

function setAppHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}

window.addEventListener('resize', setAppHeight);
window.addEventListener('orientationchange', setAppHeight);
setAppHeight();

const shuffleArray = (array) => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const NNNTraining = ({ 
  isLoading, 
  isCompleted, 
  remainingWords, 
  currentWord, 
  progress, 
  handleAnswer, 
  handleNextWord,
  currentIndex,
  totalWords,
  completedWords,
  resetTraining,
  hasProgress,
  continueTraining
}) => {
  const navigate = useNavigate();
  const _location = useLocation();
  const [showCelebration, setShowCelebration] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(!isCompleted);

  const handleReturnToMenu = () => {
    navigate('/', { replace: true });
  };

  const handleCloseCelebration = () => {
    setShowCelebration(false);
    resetTraining();
    handleReturnToMenu();
  };

  const handleNewStart = () => {
    resetTraining();
    setShowStartScreen(false);
  };

  const handleContinue = () => {
    continueTraining();
    setShowStartScreen(false);
  };

  useEffect(() => {
    if (isCompleted) {
      console.log('Training completed, showing celebration');
      setShowCelebration(true);
    }
  }, [isCompleted]);

  useEffect(() => {
    if (!hasProgress || isCompleted) {
      setShowStartScreen(false);
    }
  }, [hasProgress, isCompleted]);

  if (isLoading) {
    return <p className="loading">Загрузка...</p>;
  }

  if (showCelebration) {
    return <CelebrationScreen onClose={handleCloseCelebration} />;
  }

  if (showStartScreen && hasProgress && !isCompleted) {
    return (
      <StartScreen 
        onContinue={handleContinue}
        onNewStart={handleNewStart}
        hasProgress={hasProgress}
      />
    );
  }

  return (
    <div className="nnn-training relative">
      <RulesPopup />
      <h1>Тренажер Н/НН</h1>
      {!isCompleted && (
        <>
          <p>Осталось слов для изучения: {remainingWords}</p>
          {currentWord && (
            <WordCard
              word={currentWord.word}
              fullWord={currentWord.fullWord}
              correctAnswer={currentWord.correctAnswer}
              rule={currentWord.rule}
              correctAttempts={currentWord.correctAttempts}
              currentWordIndex={currentIndex}
              totalWords={totalWords}
              completedWords={completedWords}
              onAnswer={handleAnswer}
              onNextWord={handleNextWord}
            />
          )}
          <UserProgress progress={progress} />
          <button onClick={handleReturnToMenu} className="btn btn-primary">
            Вернуться в меню
          </button>
        </>
      )}
    </div>
  );
};

const AppContent = () => {
  const [words, setWords] = useState([]);
  const [wordQueue, setWordQueue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [completedWords, setCompletedWords] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasProgress, setHasProgress] = useState(false);

  const saveProgress = useCallback(() => {
    const progressData = {
      words,
      wordQueue,
      currentIndex,
      progress,
      completedWords,
      isCompleted
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(progressData));
  }, [words, wordQueue, currentIndex, progress, completedWords, isCompleted]);

  const loadProgress = useCallback(() => {
    const savedProgress = localStorage.getItem(STORAGE_KEY);
    if (savedProgress) {
      const parsed = JSON.parse(savedProgress);
      return parsed;
    }
    return null;
  }, []);

  const resetTraining = useCallback(() => {
    localStorage.removeItem(STORAGE_KEY);
    setWords([]);
    setWordQueue([]);
    setCurrentIndex(0);
    setProgress([]);
    setIsLoading(true);
    setCompletedWords(0);
    setIsCompleted(false);
    setHasProgress(false);
    
    const initializedWords = initialWords.map(word => ({
      ...word,
      correctAttempts: 0,
      totalAttempts: 0,
      lastAttempt: -1,
    }));
    setWords(initializedWords);
    setWordQueue(shuffleArray([...Array(initializedWords.length).keys()]));
    setIsLoading(false);
  }, []);

  const continueTraining = useCallback(() => {
    const savedProgress = loadProgress();
    if (savedProgress) {
      setWords(savedProgress.words);
      setWordQueue(savedProgress.wordQueue);
      setCurrentIndex(savedProgress.currentIndex);
      setProgress(savedProgress.progress);
      setCompletedWords(savedProgress.completedWords);
      setIsCompleted(savedProgress.isCompleted);
    }
    setIsLoading(false);
  }, [loadProgress]);

  useEffect(() => {
    const savedProgress = loadProgress();
    if (savedProgress) {
      setHasProgress(true);
      setWords(savedProgress.words);
      setWordQueue(savedProgress.wordQueue);
      setCurrentIndex(savedProgress.currentIndex);
      setProgress(savedProgress.progress);
      setCompletedWords(savedProgress.completedWords);
      setIsCompleted(savedProgress.isCompleted);
    } else {
      const initializedWords = initialWords.map(word => ({
        ...word,
        correctAttempts: 0,
        totalAttempts: 0,
        lastAttempt: -1,
      }));
      setWords(initializedWords);
      setWordQueue(shuffleArray([...Array(initializedWords.length).keys()]));
    }
    setIsLoading(false);
  }, [loadProgress]);

  useEffect(() => {
    if (!isLoading) {
      saveProgress();
    }
  }, [words, wordQueue, currentIndex, progress, completedWords, isCompleted, isLoading, saveProgress]);

  const currentWord = words[wordQueue[currentIndex]];

  const advanceQueue = useCallback((lastIndex, isCorrect) => {
    if (words.length === 0) return;

    setWordQueue(prevQueue => {
      let newQueue = [...prevQueue];
      const currentWordIndex = newQueue[lastIndex];
      
      if (currentWordIndex !== undefined && words[currentWordIndex].correctAttempts < 3) {
        newQueue = newQueue.filter((_, index) => index !== lastIndex);
        
        const minPosition = lastIndex + CORRECT_REPEAT_WINDOW;
        const maxPosition = Math.min(lastIndex + INCORRECT_REPEAT_WINDOW, newQueue.length);
        
        if (minPosition >= newQueue.length) {
          newQueue.push(currentWordIndex);
        } else {
          const insertPosition = Math.floor(Math.random() * (maxPosition - minPosition + 1)) + minPosition;
          newQueue.splice(insertPosition, 0, currentWordIndex);
        }
      }
      
      return newQueue.filter(index => words[index].correctAttempts < 3);
    });

    setCurrentIndex(0);
  }, [words]);

  const handleAnswer = useCallback((answer, isCorrect) => {
    if (!currentWord) return;
  
    setWords(prevWords => {
      const updatedWords = [...prevWords];
      const wordIndex = wordQueue[currentIndex];
      const word = { ...updatedWords[wordIndex] };
      const wasCompletedBefore = word.correctAttempts === 3;
      
      if (isCorrect) {
        word.correctAttempts = Math.min(word.correctAttempts + 1, 3);
        word.consecutiveCorrectAttempts++;
        
        if (word.correctAttempts === 3 && !wasCompletedBefore) {
          setCompletedWords(prev => prev + 1);
        }
      } else {
        word.correctAttempts = 0;
        word.consecutiveCorrectAttempts = 0;
      }
      word.totalAttempts++;
      word.lastAttempt = currentIndex;
      word.lastAttemptCorrect = isCorrect;
      
      updatedWords[wordIndex] = word;
      
      const allWordsCompleted = updatedWords.every(w => w.correctAttempts === 3);
      if (allWordsCompleted) {
        console.log('All words completed, setting isCompleted');
        setIsCompleted(true);
      }

      setHasProgress(true);
      
      return updatedWords;
    });
  
    setProgress(prev => [...prev, { 
      word: currentWord.fullWord,
      correct: isCorrect 
    }]);
  
  }, [currentIndex, currentWord, wordQueue]);

  const handleNextWord = useCallback(() => {
    if (!currentWord) return;
    advanceQueue(currentIndex, currentWord.correctAttempts > 0);
    
    const remainingWords = words.filter(word => word.correctAttempts < 3);
    if (remainingWords.length === 0) {
      setIsCompleted(true);
    }
  }, [advanceQueue, currentIndex, currentWord, words]);

  const remainingWords = useMemo(() => {
    return words.filter(word => word.correctAttempts < 3).length;
  }, [words]);

  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Menu />} />
        <Route 
          path="/nnn-training" 
          element={
            <NNNTraining 
              isLoading={isLoading}
              isCompleted={isCompleted}
              remainingWords={remainingWords}
              currentWord={currentWord}
              progress={progress}
              handleAnswer={handleAnswer}
              handleNextWord={handleNextWord}
              currentIndex={currentIndex}
              totalWords={initialWords.length}
              completedWords={completedWords}
              resetTraining={resetTraining}
              hasProgress={hasProgress}
              continueTraining={continueTraining}
            />
          } 
        />
        <Route path="/rules" element={<Rules />} />
        <Route path="/exam-tasks" element={<EGESection />} />
      </Routes>
      <footer>
    <p>© 2024 FENA <br />Bce права защищены</p>
    <p className="recaptcha-notice">Этот сайт защищен reCAPTCHA и применяются <a href="https://policies.google.com/privacy">Политика конфиденциальности</a> и <a href="https://policies.google.com/terms">Условия использования</a> Google.</p>
</footer>
    </div>
  );
};

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcsVo8qAAAAAIncdV7i2cnbujZDhcQvOfmSlLwZ">
      <Router 
        basename="/"
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true
        }}
      >
        <AppContent />
      </Router>
    </GoogleReCaptchaProvider>
  );
};

export default App;