import React, { useState, useRef, useEffect } from 'react';
import { X } from 'lucide-react';

const RulesPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(true)}
        className="rules-button"
      >
        Правила игры
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 modal-overlay">
          <div ref={modalRef} className="modal-content">
            <button
              onClick={() => setIsOpen(false)}
              className="modal-close"
            >
              <X size={20} />
            </button>
            
            <h2 className="modal-title">Правила игры</h2>
            
            <div className="modal-inner-content">
              <p className="modal-text">
                Этот тренажер предназначен для быстрого запоминания слов-исключений из 15 задания ЕГЭ по русскому языку.
              </p>
              
              <p className="modal-text">
                Его логика проста: выбирай количество «н» в слове, если ответ верный-карточка загорается зеленым цветом, неверный-красным.
              </p>
              
              <p className="modal-text">
                В правом углу ты увидишь индикатор «жизней», который определяет количество правильных ответов. После того как ты верно ответил на слово 3 раза подряд (3 закрашенных сердечка) слово считается пройденным.
              </p>
              
              <p className="modal-text">
                В случае если ты ответил неверно, то твой прогресс (в виде закрашенных сердечек) возвращается в начальное положение (0 из 3 закрашенных сердечек).
              </p>
              
              <p className="modal-text modal-text-emphasis">
                Желаем удачи!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RulesPopup;