export const words = [

    { word: 'багря__ый', fullWord: 'багряный', correctAnswer: 'Н', difficulty: 1, rule: 'в слове «багряный» одна буква «н» пишется по правилу написания прилагательных с суффиксом -ан- (-ян-,-ин-), образованных от существительных. Исключения: оловянный, деревянный, стеклянный', id: 1, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'верчё__ый', fullWord: 'верчёный', correctAnswer: 'Н', difficulty: 1, rule: 'сеченый, мученый, крученый, верченый -отглагольные прилагательные. Будут писаться с НН, если появится приставка: крученый - закрученный, или появятся зависимые слова: сеченный толстым кнутом', id: 2, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'ставле__ик', fullWord: 'ставленник', correctAnswer: 'НН', difficulty: 2, rule: 'слово «ставленник» пишется с двумя буквами «НН», потому что оно образовано от причастия «ставленный». Написание проверяется в словарном порядке.', id: 3, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'воро__ой', fullWord: 'вороной', correctAnswer: 'Н', difficulty: 1, rule: 'одна "н",потому что вороной-первообразное прилагательное. Вот примеры: юный, единый, свиной, буланый, синий, зелёный, красный, пьяный, пряный, рдяный, рьяный, багряный, румяный', id: 4, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'безымя__ый', fullWord: 'безымянный', correctAnswer: 'НН', difficulty: 2, rule: 'слово образовано от сущ. «имя». Прилагательные, образованные от существительных на МЯ (время, пламя, семя и др.): временный, пламенный, семенной пишутся с «НН»', id: 5, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'племя__ик', fullWord: 'племянник', correctAnswer: 'НН', difficulty: 2, rule: 'слово образовано от сущ. «племя». Прилагательные , образованные от существительных на МЯ (время, пламя, семя и др.) : временный, пламенный, семенной пишутся с «НН»', id: 6, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'гости__ая', fullWord: 'гостиная', correctAnswer: 'Н', difficulty: 1, rule: 'в слове «гостиная» одна буква «н» пишется, потому что оно образовано от существительного «гость» с помощью суффикса «ин». По правилам русского языка прилагательные с суффиксами «ин», «ан», «ян» пишутся с одной буквой «н»', id: 7, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'зелё__ый', fullWord: 'зелёный', correctAnswer: 'Н', difficulty: 1, rule: 'в слове «зелёный» одна буква «н» пишется, потому что это непроизводное прилагательное (не образовано от других слов).Буква «н» относится к корню в таких прилагательных', id: 8, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'неожида__ый', fullWord: 'неожиданный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «неожиданный» пишется с двумя буквами «НН», так как оно является исключением из правила, согласно которому у отглагольных прилагательных может быть как суффикс «-н-», так и «-нн-». Это прилагательное связано с глаголом «ожидать» несовершенного вида.', id: 9, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'крещё__ый', fullWord: 'крещёный', correctAnswer: 'Н', difficulty: 1, rule: 'одна буква «Н» в слове «крещёный» используется при отсутствии зависимых слов. Две буквы «Н» используются при наличии зависимых слов. Например: «крещённый вчера младенец»', id: 10, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'пря__ый', fullWord: 'пряный', correctAnswer: 'Н', difficulty: 1, rule: 'в слове «пряный» одна буква «н» пишется, потому что это непроизводное прилагательное (у которого нет суффиксов). Буква «н» в нём находится в корне', id: 11, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'пья__ый', fullWord: 'пьяный', correctAnswer: 'Н', difficulty: 1, rule: 'одна буква «н» пишется, потому что это непроизводное прилагательное (у которого нет суффиксов). Буква «н» в нём находится в корне', id: 12, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'роже__ица', fullWord: 'роженица', correctAnswer: 'Н', difficulty: 1, rule: 'словарное слово, нужно запомнить', id: 13, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'вида__ый', fullWord: 'виданный', correctAnswer: 'НН', difficulty: 2, rule: 'в слове «виданный» две буквы «НН» потому, что оно является полным страдательным причастием (прошедшего времени, от глагола «видать»). Согласно правилу, в суффиксах полных страдательных причастий всегда пишется две буквы «Н»', id: 14, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'слыша__ый', fullWord: 'слышанный', correctAnswer: 'НН', difficulty: 2, rule: 'исключение, необходимо запомнить', id: 15, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'доморощё__ый', fullWord: 'доморощенный', correctAnswer: 'НН', difficulty: 2, rule: 'в слове «доморощенный» две буквы «НН», так как оно образовано от сочетания «растить дома» и имеет зависимое слово. Примеры: --- доморощенный табак --- выращенный дома; --- доморощенный поэт --- заурядный, примитивный', id: 16, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'подли__ый', fullWord: 'подлинный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «подлинный» пишется с двумя «нн», так как исторически в нём выделяется корень «длин-» и суффикс «-н-», как в слове «длинный». Есть версия, что значение «настоящий» появилось у слова «подлинный» потому, что раньше при допросе, добиваясь истины, подозреваемого били длинными палками --- подлинниками', id: 17, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'сангви__ик', fullWord: 'сангвиник', correctAnswer: 'Н', difficulty: 1, rule: 'словарное слово', id: 18, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'виде__ый', fullWord: 'виденный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «виденный» относится к числу слов-исключений с -нн- Большую часть слов-исключений с -нн- можно быстрее запомнить, если связать их с подарком: подарок (какой?) невиданный, неслыханный, негаданный, желанный, виденный, обещанный и т.д.', id: 19, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'оловя__ый', fullWord: 'оловянный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «оловянный» является исключением из правила написания одной буквы «Н» в прилагательных. Одна «Н» пишется в прилагательных, в суффиксах которых есть -ан-, -ян-, -ин-. Исключения: оловянный, деревянный, стеклянный.', id: 20, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'серебре__ик', fullWord: 'серебреник', correctAnswer: 'Н', difficulty: 1, rule: 'в сущ. с суффиксом -енн- обычно пишется две буквы «Н»: путешественник, предшественник, единомышленник, утопленник, утренник и тд. Исключения составляют слова: «серебреник»«труженик», «ученик», «мученик»,и «вареник»', id: 21, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'сви__ой', fullWord: 'свиной', correctAnswer: 'Н', difficulty: 1, rule: '(и др. притяжательные) «свиной» пишется с одной буквой «н», потому что в нём отсутствует суффикс. В этом прилагательном есть только корень «свин» и окончание «ой», единственная «н» относится к корню', id: 22, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'суже__ый', fullWord: 'суженый', correctAnswer: 'Н', difficulty: 1, rule: 'прилагательное,написание нужно запомнить', id: 23, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'чека__ый', fullWord: 'чеканный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 24, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'чва__ый', fullWord: 'чванный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 25, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'ядрё__ый', fullWord: 'ядрёный', correctAnswer: 'Н', difficulty: 1, rule: 'слово «ядрёный» пишется с одной буквой «Н», так как оно является исключением из правил и не имеет суффикса', id: 26, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'ю__ый', fullWord: 'юный', correctAnswer: 'Н', difficulty: 1, rule: 'это первообразное прилагательное (не образованное ни от каких других слов)', id: 27, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'румя__ый', fullWord: 'румяный', correctAnswer: 'Н', difficulty: 1, rule: '«румяный» пишется с одной буквой «н», потому что в нём отсутствует суффикс. «Ян» в этом прилагательном --- часть корня', id: 28, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'бара__ий', fullWord: 'бараний', correctAnswer: 'Н', difficulty: 1, rule: '(и другие прит. прилаг.) суффикс просто добавляется к корню слова: баран + ий = бараний. В слове баран была одна буква н, так она и осталась, присоединив к себе суффикс ий', id: 29, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'жела__ый', fullWord: 'желанный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 30, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'жема__ый', fullWord: 'жеманный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 31, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'нежда__ый', fullWord: 'нежданный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 32, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'була__ый', fullWord: 'буланый', correctAnswer: 'Н', difficulty: 1, rule: 'слово «буланый» пишется с одной буквой «н», которая относится к корню. Это прилагательное относится к масти лошади или разновидности окраски. Вероятнее всего, оно имеет тюркские корни', id: 33, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'ветре__ый', fullWord: 'ветреный', correctAnswer: 'Н', difficulty: 1, rule: '(!но: безветренный)является исключением из общего правила, Если появится приставка или добавится зависимое слово, пишите -нн-', id: 34, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'венча__ый', fullWord: 'венчанный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «венчанный» пишется с двумя «нн», потому что оно образовано от двувидового глагола «венчать». Например: «Батюшка (что делал? / что сделал?) венчал их в церкви». В некоторых других словах, образованных от двувидовых глаголов, (например: жененный, завещанный, казненный, обещанный, рожденный), также пишется удвоенная «н»', id: 35, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'ра__еный', fullWord: 'раненый', correctAnswer: 'Н', difficulty: 1, rule: 'и тяжелораненый, в слове «раненый» нет ни приставки, ни суффикса --ова-(-ева-), а образовано оно от глагола несовершенного вида, поэтому обычно в нем пишется одно «н».(!но: израненный, тяжело раненный)', id: 36, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'беше__ый', fullWord: 'бешеный', correctAnswer: 'Н', difficulty: 1, rule: 'это имя прилагательное всегда пишется только с одной буквой "н"', id: 37, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'обеща__ый', fullWord: 'обещанный', correctAnswer: 'НН', difficulty: 2, rule: 'страдательное причастие прошедшго времени, образованное от глагола обещать с помощью суффикса -нн-', id: 38, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'бесприда__ица', fullWord: 'бесприданница', correctAnswer: 'НН', difficulty: 2, rule: 'слово «бесприданница» пишется с -НН-, так как образовано суффиксальным способом от существительного «приданое» ( «имущество невесты»), которое оканчивается на -Н + суффикс --НИЦ-.', id: 39, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'смышлё__ый', fullWord: 'смышлёный', correctAnswer: 'Н', difficulty: 1, rule: 'смышленый является исключением из правил. Оно пишется с одной "н", также как и посаженый (отец), названый (брат) . Его написание следует запомнить', id: 40, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'невида__ый', fullWord: 'невиданный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 41, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'нечая__ый', fullWord: 'нечаянный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 42, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'недрема__ый', fullWord: 'недреманный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 43, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'стира__ый', fullWord: 'стираный', correctAnswer: 'Н', difficulty: 1, rule: 'в полных и кратких формах отглагольных прилагательных, которые образованы от бесприставочных глаголов несовершенного вида, пишется одна «н» (стираный, жареный, глаженый). Если появится приставка или добавится зависимое слово, пишите -нн-', id: 44, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'кова__ый', fullWord: 'кованый', correctAnswer: 'Н', difficulty: 1, rule: 'исключение, если появится приставка или добавится зависимое слово, пишите -нн-', id: 45, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'жёва__ый', fullWord: 'жёваный', correctAnswer: 'Н', difficulty: 1, rule: 'исключение, если появится приставка или добавится зависимое слово, пишите -нн-', id: 46, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'медле__ый', fullWord: 'медленный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный.', id: 47, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'негада__ый', fullWord: 'негаданный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 48, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'клёва__ый', fullWord: 'клёваный', correctAnswer: 'Н', difficulty: 1, rule: 'исключение, если появится приставка или добавится зависимое слово, пишите -нн-', id: 49, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'назва__ый', fullWord: 'названый', correctAnswer: 'Н', difficulty: 1, rule: 'названый ( с ударением на втором слоге) - употребляется в сочетании "названый брат". Названый брат, названный в честь отца', id: 50, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'свяще__ый', fullWord: 'священный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный.', id: 51, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'посажё__ый', fullWord: 'посажёный', correctAnswer: 'Н', difficulty: 1, rule: 'исключение, это тот , кто выполняет роль отца на свадьбе, но не является родным', id: 52, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'конче__ый', fullWord: 'конченый', correctAnswer: 'Н', difficulty: 1, rule: 'устойчивое сочетание, запомнить', id: 53, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'писа__ая', fullWord: 'писаная', correctAnswer: 'Н', difficulty: 1, rule: 'писаная красавица (прилагательное, образ. от глагола "писать" что делать? несов. вида)', id: 54, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'стекля__ый', fullWord: 'стеклянный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «стеклянный» является исключением из правила написания одной буквы «Н» в прилагательных. Одна «Н» пишется в прилагательных, в суффиксах которых есть -ан-, -ян-, -ин-. Исключения: оловянный, деревянный, стеклянный.', id: 55, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'неслыха__ый', fullWord: 'неслыханный', correctAnswer: 'НН', difficulty: 2, rule: 'если отглагольное прилагательное образовано от глагола несовершенного вида, не имеет приставок и зависимых слов. Например: стираный, жареный. Исключения: медленный, желанный, отчаянный, деланный, священный, читанный, данный, чеканный, окаянный, жёманный, нежданный, негаданный, неслыханный, невиданный, нечаянный, неожиданный', id: 56, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'прощё__ое', fullWord: 'прощёное', correctAnswer: 'Н', difficulty: 1, rule: 'слово прощёное в единстве со словом "воскресенье" является прилагательным и обозначает только признак предмета. Пишется оно традиционно с одной буквой "н" в суффиксе', id: 57, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'деревя__ый', fullWord: 'деревянный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «деревянный» является исключением из правила написания одной буквы «Н» в прилагательных. Одна «Н» пишется в прилагательных, в суффиксах которых есть -ан-, -ян-, -ин-. Исключения: оловянный, деревянный, стеклянный.', id: 58, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'трезве__ик', fullWord: 'трезвенник', correctAnswer: 'НН', difficulty: 2, rule: 'существительное трезвенник произошло от отымённого прилагательного "трезвенный". По правилам русского языка в отымённых прилагательных в суффиксах -ОНН-,-ЕНН-,пишется «НН» Значит слово трезвенник пишется с двумя «НН».', id: 59, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'глаже__ое', fullWord: 'глаженое', correctAnswer: 'Н', difficulty: 1, rule: 'в сочетаниях типа «глаженый-переглаженый», «ношеный-переношеный» пишется одна буква «н», так как эти слова считаются прилагательными', id: 60, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'лата__ая', fullWord: 'латаная', correctAnswer: 'Н', difficulty: 1, rule: 'В сочетаниях типа «глаженый-переглаженый», «ношеный-переношеный» пишется одна буква «н», так как эти слова считаются прилагательными', id: 61, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'труже__ик', fullWord: 'труженик', correctAnswer: 'Н', difficulty: 1, rule: 'слово «труженик» пишется с одной «н», потому что оно является исключением из правила о существительных с суффиксом «-енн»', id: 62, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'окая__ый', fullWord: 'окаянный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «окаянный» пишется с двумя буквами «НН», так как оно образовано от глагола совершенного вида «окаяти» (осудить).', id: 63, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'отчая__ый', fullWord: 'отчаянный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «отчаянный» относится к исключениям, в которых всегда пишется две буквы «НН».', id: 64, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'повере__ый', fullWord: 'поверенный', correctAnswer: 'НН', difficulty: 2, rule: '«нн» в прилагательном от глагола сов. вида (поверить)', id: 65, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'преда__ый', fullWord: 'преданный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «преданный» всегда пишется с двумя «н», несмотря на то, что оно может обозначать разные понятия.', id: 66, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'рождё__ый', fullWord: 'рождённый', correctAnswer: 'НН', difficulty: 2, rule: 'образовано от двувидового глагола «родить» В некоторых других словах, образованных от двувидовых глаголов, (например: жененный, завещанный, казненный, обещанный, рожденный), также пишется удвоенная «н».', id: 67, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'муче__ик', fullWord: 'мученик', correctAnswer: 'Н', difficulty: 1, rule: 'является исключением из правила о существительных с суффиксом «-енн»', id: 68, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'стра__ый', fullWord: 'странный', correctAnswer: 'НН', difficulty: 2, rule: 'это прилагательное произошло от существительного «страна» (в значении «сторона», т. е. что-то чужое, далёкое). К корню «стран-» был добавлен словообразовательный суффикс -н-, и в результате на стыке морфем появилось «нн».', id: 69, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'бессребре__ик', fullWord: 'бессребреник', correctAnswer: 'Н', difficulty: 1, rule: 'является исключением из правила о существительных с суффиксом «-енн»', id: 70, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'казнё__ый', fullWord: 'казнённый', correctAnswer: 'НН', difficulty: 2, rule: 'образовано от двувидового глагола «казнить» В некоторых других словах, образованных от двувидовых глаголов, (например: жененный, завещанный, казненный, обещанный, рожденный), также пишется удвоенная «н»', id: 71, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'прида__ое', fullWord: 'приданое', correctAnswer: 'Н', difficulty: 1, rule: '«приданое» пишется с одной буквой «н», если речь идёт о существительном --- имуществе невесты, которое она внесёт в семью после свадьбы', id: 72, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'мороже__ое', fullWord: 'мороженое', correctAnswer: 'Н', difficulty: 1, rule: 'слово «мороженое» пишется с одной буквой «н», если в предложении оно играет роль существительного или прилагательного', id: 73, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'завеща__ый', fullWord: 'завещанный', correctAnswer: 'НН', difficulty: 2, rule: 'образовано от двувидового глагола «завещать» В некоторых других словах, образованных от двувидовых глаголов, (например: жененный, завещанный, казненный, обещанный, рожденный), также пишется удвоенная «н»', id: 74, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'недюжи__ый', fullWord: 'недюжинный', correctAnswer: 'НН', difficulty: 2, rule: 'образовано от слова «дюжина», поэтому на стыке морфем получается две НН.', id: 75, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'пудре__ица', fullWord: 'пудреница', correctAnswer: 'Н', difficulty: 1, rule: '(пудрить - пудреный - пудреница) аналогично образованы слова: маслить - масленый - масленица; сушить - сушёный - сушеница', id: 76, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'блаже__ый', fullWord: 'блаженный', correctAnswer: 'НН', difficulty: 2, rule: 'слово «блаженный» пишется с двумя буквами «н», так как после корня «блаж» идёт суффикс «енн»', id: 77, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    { word: 'самонадея__ый', fullWord: 'самонадеянный', correctAnswer: 'НН', difficulty: 2, rule: 'написание суффикса ­НН­ мотивируется глаголом "надеяться", и как вы уже поняли, здесь суффикс не ЯНН, а Я и НН. То же самое явление наблюдается ещё и в прилагательном - самозванный (звать).', id: 78, correctAttempts: 0, totalAttempts: 0, lastAttemptCorrect: false, consecutiveCorrectAttempts: 0, nextAppearance: 0 },
    
    
    ];