import React from 'react';

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar-container">
      <div 
        className="progress-bar-fill" 
        style={{ width: `${progress}%` }}
      >
        <span className="progress-percentage">{Math.round(progress)}%</span>
      </div>
    </div>
  );
};

export default ProgressBar;
