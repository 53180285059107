import React from 'react';
import { useNavigate } from 'react-router-dom';

function Rules() {
  const navigate = useNavigate();

  const handleReturnToMenu = () => {
    navigate('/', { replace: true });
  };

  return (
    <div className="rules-container">
      <h1>Правила написания Н/НН</h1>
      <div className="rules-content">
        <section className="rule-section">
          <h2>Основной алгоритм</h2>
          <p>Для слов, отвечающих на вопрос КАКОЙ?, необходимо определить происхождение слова:</p>
          <ol>
            <li>от ГЛАГОЛА (сеяный овёс – от глагола сеять)</li>
            <li>от СУЩЕСТВИТЕЛЬНОГО (соломенная крыша – от существительного солома)</li>
          </ol>
        </section>

        <section className="rule-section">
          <h2>Вариант 1: Слово образовано от глагола</h2>
          <ol>
            <li>Если глагол совершенного вида (что сделать?) - пишем НН</li>
            <li>Если глагол несовершенного вида (что делать?):</li>
            <ul>
              <li>Если глагол на -овать/-евать - пишем НН</li>
              <li>Если есть зависимое слово - пишем НН</li>
              <li>В остальных случаях - пишем Н</li>
            </ul>
          </ol>
        </section>

        <section className="rule-section">
          <h2>Вариант 2: Слово образовано от существительного</h2>
          <ol>
            <li>Суффиксы -ОНН, -ЕНН - пишем НН (кроме ветреный)</li>
            <li>Суффиксы -АН, -ЯН, -ИН - пишем Н (кроме стеклянный, оловянный, деревянный)</li>
            <li>Суффикс -Н-:</li>
            <ul>
              <li>Если основа на Н - пишем НН</li>
              <li>В остальных случаях - пишем Н</li>
            </ul>
          </ol>
        </section>

        <section className="rule-section">
          <h2>Особые случаи</h2>
          <ol>
            <li>В кратких причастиях - всегда одна Н</li>
            <li>В кратких прилагательных - как в полных</li>
            <li>В наречиях - как в прилагательных, от которых образованы</li>
            <li>В существительных - как в прилагательных, от которых образованы</li>
          </ol>
        </section>

        <section className="important-notice">
          <h2>Важно!</h2>
          <p>
            Есть слова, которые выбиваются из данного алгоритма и написание который нужно запомнить. 
            Именно для запоминания таких слов мы создали онлайн-тренажёр, который ты можешь пройти, 
            вернувшись в меню и нажав на «Тренажёр Н/НН»
          </p>
        </section>
      </div>
      <button onClick={handleReturnToMenu} className="btn btn-primary">Вернуться в меню</button>
    </div>
  );
}

export default Rules;