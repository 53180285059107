import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const CelebScreenEGE = ({ onNavigateHome, allTasksCompleted }) => {
  const [_showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const generateFireworks = () => {
    return Array.from({ length: 12 }).map((_, i) => (
      <div
        key={i}
        className="EGEfirework"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 2}s`
        }}
      />
    ));
  };

  return (
    <div className="EGEcelebration-container">
      <div className="EGErelative w-full h-full overflow-hidden">
        {generateFireworks()}

        <div className="EGEcelebration-content">
          <h2 className="EGEcelebration-title">Поздравляю тебя, мой друг!</h2>

          <p className="EGEcelebration-message">
            Ты прошёл все задания!
          </p>

          <button
            onClick={() => navigate('/')}
            className="EGEcelebration-button"
          >
            Вернуться в меню
          </button>
        </div>
      </div>
    </div>
  );
};

export default CelebScreenEGE;