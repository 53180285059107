// UserProgress.js
import React from 'react';

const UserProgress = ({ progress }) => {
  const recentProgress = progress.slice(-3).reverse();

  return (
    <div className="progress-container">
      <h3 className="progress-title">История ответов</h3>
      {recentProgress.length > 0 ? (
        <div className="progress-list">
          {recentProgress.map((item, index) => (
            <div
              key={index}
              className={`progress-item ${item.correct ? 'correct' : 'incorrect'}`}
            >
              <span className="progress-word">{item.word}</span>
              <span className={`progress-indicator ${item.correct ? 'correct' : 'incorrect'}`}>
                {item.correct ? '✓' : '✗'}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <p className="progress-empty">Пока нет ответов</p>
      )}
    </div>
  );
};

export default UserProgress;