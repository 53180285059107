import React, { useState, useEffect } from 'react';
import { Send, Mail, ChevronDown, X } from 'lucide-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SendMailForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isOpen, setIsOpen] = useState(false);
  const [showBotInstructions, setShowBotInstructions] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    telegram_username: '',
    message: ''
  });
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false,
    error: null
  });
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('form-open');
      
      const handleResize = () => {
        const isKeyboard = window.innerHeight < window.outerHeight;
        setIsKeyboardVisible(isKeyboard);
        
        const contentElement = document.querySelector('.contact-content');
        if (contentElement) {
          if (isKeyboard && window.innerWidth <= 767) {
            contentElement.classList.add('keyboard-visible');
          } else {
            contentElement.classList.remove('keyboard-visible');
          }
        }
      };

      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
        document.body.classList.remove('form-open');
      };
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'telegram_username') {
      const cleanedValue = value.replace(/^@+/, '');
      setFormData(prev => ({ ...prev, [name]: cleanedValue }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (status.submitting) return;
    
    setStatus({ submitting: true, submitted: false, error: null });
  
    try {
      const token = await executeRecaptcha('submit_form');
      
      const response = await fetch('/send_mail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          captchaToken: token
        })
      });
      
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Ошибка отправки сообщения');
      }

      setStatus({ submitting: false, submitted: true, error: null });
      setFormData({ name: '', email: '', telegram_username: '', message: '' });
      setShowBotInstructions(true);
    } catch (err) {
      setStatus({ 
        submitting: false, 
        submitted: false, 
        error: err.message || 'Произошла ошибка при отправке сообщения'
      });
    }
  };

  if (!isOpen) {
    return (
      <div className="contact-section">
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="contact-trigger"
        >
          <span>Обратная связь</span>
          <ChevronDown className={`chevron-icon ${isOpen ? 'rotate' : ''}`} />
        </button>
      </div>
    );
  }

  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 767;

  return (
    <>
      <div className="modal-overlay" onClick={() => setIsOpen(false)} />
      <div className={`contact-content ${isKeyboardVisible && isMobile ? 'keyboard-visible' : ''}`}>
        <button 
          type="button"
          onClick={() => setIsOpen(false)}
          className="close-button"
          aria-label="Закрыть форму"
        >
          <X className="close-icon" />
        </button>

        <div className="contact-container">
          <div className="contact-header">
            <h3>Есть вопросы или предложения?</h3>
            <p>Мы всегда на связи</p>
          </div>

          <form onSubmit={handleSubmit} className="contact-form">
            <input
              type="text"
              name="name"
              placeholder="Ваше имя"
              value={formData.name}
              onChange={handleChange}
              required
              disabled={status.submitting}
            />
            <input
              type="email"
              name="email"
              placeholder="Ваш email (необязательно)"
              value={formData.email}
              onChange={handleChange}
              disabled={status.submitting}
            />
            <input
              type="text"
              name="telegram_username"
              placeholder="Ваш username в Telegram (обязательно)"
              value={formData.telegram_username}
              onChange={handleChange}
              required
              disabled={status.submitting}
            />
            <textarea
              name="message"
              placeholder="Ваше сообщение"
              value={formData.message}
              onChange={handleChange}
              required
              disabled={status.submitting}
            />

            <button 
              type="submit"
              className="submit-button"
              disabled={status.submitting}
            >
              <span>{status.submitting ? 'Отправка...' : 'Отправить'}</span>
              <Send className="send-icon" />
            </button>
          </form>

          {status.submitted && (
            <div className="success-message">
              <p>Спасибо за ваше сообщение!</p>
              {showBotInstructions && (
                <div className="bot-instructions">
                  <p>Чтобы получить ответ, выполните следующие действия:</p>
                  <ol>
                    <li>Перейдите к боту <a href="https://t.me/egeexam_help_bot" target="_blank" rel="noopener noreferrer">@egeexam_help_bot</a></li>
                    <li>Нажмите кнопку СТАРТ</li>
                    <li>После этого вы получите ответ от администратора</li>
                  </ol>
                  <button 
                    onClick={() => {
                      setShowBotInstructions(false);
                      setIsOpen(false);
                    }}
                    className="submit-button"
                  >
                    Понятно
                  </button>
                </div>
              )}
            </div>
          )}

          {status.error && (
            <div className="error-message">
              {status.error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SendMailForm;