export const egeWords = [
  {
    id: 1,
    word: "Укажите все цифры, на месте которых пишется НН.\n\nВпоследствии я разыскал в кладовке какие-то необыкнове(1)ые рукописи, переплете(2)ые в тома и писа(3)ые по-латыни.",
    correctAnswer: "123",
    rule: "Пояснение:\n\nВпоследствии я разыскал в кладовке какие-то необыкновенные рукописи, переплетенные в тома и писанные по-латыни.\n\nнеобыкновенные —прилагат., традиционное написание\n\nпереплетенные—причастие сов. вид;\n\nписанные (как? чем?)—прич. с зависимым словом."
  },
  {
    id: 2,
    word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nКниг у прабабушки было много: стари(1)ые церковные, с металлическими застёжками, в переплётах из тиснё(2)ой сви(3)ой кожи, подшивка журнала «Крокодил» за 1938 год и целая вязанка бесце(4)ых произведений русских классиков.",
    correctAnswer: "14",
    rule: "Пояснение:\n\n1) -НН- в прилагательном на стыке двух суффиксов: старин+н+ый;\n\n2) -Н- в суффиксе отглагольного прилагательного;\n\n3) -Н- в корне: свин+ой;\n\n4) -НН- на стыке корня и суффикса."
  },
  {
    id: 3,
    word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nРазразилась буря: беше(1)о визжит ветер, мчатся рыжие, словно в клочья разорва(2)ые облака; захлестал, закачался отвесными столбами рья(3)ый ливень, молнии слепят огнистой зеленью, грохочет отрывистый гром. Всё вокруг занавеше(4)о пеленой дождя.",
    correctAnswer: "2",
    rule: "Пояснение:\n\nбешено: наречие, Н потому, что в прилагательном бешеный Н.;\n\nразорваННые — полное страдательное причастие;\n\nрьяНый — нет суффикса, это корень рьян;\n\nзанавешеНо — Н в кратком причастии."
  },
  {
    id: 4,
    word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nНарод-патриот выступил на поле Бородина, где французы потеряли убитыми и ране(1)ыми более 58 тысяч солдат и офицеров, подли(2)ым творцом истории и убедительно доказал всему миру, что нет на земле большей силы, чем народные массы, сплочё(3)ые народными вождями для достижения величестве(4)ой, понятной и близкой их сердцу цели.",
    correctAnswer: "234",
    rule: "Пояснение:\n\nранеными — слово-исключение, с одной Н;\n\nподлинными— от существительного «подлинник» (исторически НН, этимологически от слова длина);\n\nсплочённые— причастие, НН;\n\nвеличествЕННой—от сущ. величество +ЕНН."
  },
  {
    id: 5,
    word: "Укажите все цифры, на месте которых пишется НН.\n\nТак оно было или не так, хорошо или плохо выдума(1)о, но первая Колюнина любовь оказалась несчастной; он не пытался ничего добиться, а если бы и добился, то не знал бы, что с этой бесце(2)ой добычей делать, и летними вечерами он слушал с трудом купле(3)ый в универмаге кассетный магнитофон «Весна», зажигал свечи, разговаривал с далёкими, будто золочё(4)ыми огоньками у черты горизонта.",
    correctAnswer: "23",
    rule: "Пояснение:\n\nвыдумано — краткое причастие, всегда Н;\n\nбесценной—от сущ. цена + н;\n\nкупленный — причастие с зависимым словом или причастие от глагола сов. вида;\n\nзолочёными —отглагольное прилагательное, от глагола золотить несов. вида. Слово «будто» является союзом, не является членом предложения, поэтому не может быть зависимым словом."
  },
  {
    id: 6,
    word: "Укажите все цифры, на месте которых пишется НН.\n\nНе для того наш народ создал для нас и для наших потомков богатый, свободный и сильный язык, поражающий своими изощрё(1)ыми, гибкими, бесконечно разнообразными формами; не для того нам оставле(2)о в дар это бесце(3)ое сокровище нашей национальной культуры, чтобы мы, с презрением забросив его, свели свою речь к нескольким десяткам штампова(4)ых фраз.",
    correctAnswer: "134",
    rule: "Пояснение:\n\nИзощрённый— 1. прич. страд. прош. от изощрить. 2. в знач. прил. Превосходно развитый, тонкий, острый. В данном предложении это слово—прилагательное, но по происхождению восходящее к причастию. Поэтому и НН: изощрить +ЕНН, убираем И основы глагола."
  },
  {
    id: 7,
    word: "Укажите все цифры, на месте которых пишется НН.\n\nТеaтр был огромный, с кaме(1)ыми коло(2)aми: нa крыше его взвивaлись нa дыбы чугу(3)ые лошaди, и их беше(4)ый порыв мужестве(5)о сдерживaл человек с венком нa голове, должно быть сильный и хрaбрый.",
    correctAnswer: "1235",
    rule: "Пояснение:\n\nКаменными — камен- + -Н-в прилагательном. Колоннами — существительное с НН. Чугунные — от «чугун» +суффикс -Н-. Бешеный — отглагольное прилагательное с одной -Н-. Мужественно- от «мужественный», поэтому -НН- сохраняется."
  },
  {
    id: 8,
    word: "Укажите все цифры, на месте которых пишется НН.\n\nСтарообрядцы сдержа(1)о посовещались, и один из них, пригибаясь, подошёл к печи и положил на колени Яшкина круглый каравай хлеба, кусок варё(2)ого мяса, две луковицы и берестя(3)ое лукошко с солью, сдела(4)ое в виде пенала.",
    correctAnswer: "14",
    rule: "Пояснение:\n\nВ этом предложении:\n\nсдержаННо — КАК? наречие, образованное от причастия СДЕРЖАННЫЙ (совершенный вид);\n\nвареНого — отглагольное прилагательное без приставки и зависимых слов;\n\nберестяНое — прилагательное, образованное от существительного БЕРЕСТА с помощью суффикса -ЯН-;\n\nсделаННое — причастие, образованное от глагола совершенного вида СДЕЛАТЬ."
  },
  {
    id: 9,
    word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nПри оформлении помещения в стиле кантри соверше(1)о противопоказа(2)ы хромирова(3)ый металл и высокотехнологичные изделия из стекла и пластика. Металлическим деталям в таком интерьере можно придать состаре(4)ый вид, тогда они отлично впишутся в общий дизайн.",
    correctAnswer: "134",
    rule: "Пояснение:\n\nсовершенно— наречие от прилагательного совершенный;\n\nпротивопоказаны— краткое причастие;\n\nхромированный— соотносительное с полным страдательным причастием отглагольное прилагательное на -ованный пишется с двумя буквами -н-;\n\nсостаренный— полное страдательное причастие."
  },
  {
    id: 10,
    word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nНа этом торжестве(1)ом столе, покрытом тонкой накрахмале(2)ой скатертью с большими вензелями, стояли серебря(3)ый кофейник с пахучим кофе, сливочник с кипячё(4)ыми сливками, корзина со свежепечё(5)ым калачом, подле прибора лежали получе(6)ые письма и газеты.",
    correctAnswer: "126",
    rule: "Пояснение:\n\n1) -НН- в суффиксе -енн-;\n\n2) -НН- в причастии;\n\n3) -Н- в суффиксе -ян-;\n\n4) -Н- в суффиксе отглагольного прилагательного;\n\n5) -Н- в суффиксе отглагольного прилагательного;\n\n6) -НН- в суффиксе причастия, образованного от глагола совершенного вида."
  },
  {
      id: 11,
      word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nКогда-то зелёная крыша, давно не краше(1)ая, краснела от ржавчины, и несколько листов были задра(2)ы кверху, вероятно бурей; некоторые окна вместо стекла были задела(3)ы тёсом. Листве(4)ица, которая была посаже(5)а Софьей Ивановной около дома, была теперь большим деревом. И только на лугу за рекой всё так же паслось пёстрое смеша(6)ое крестьянское стадо.",
      correctAnswer: "146",
      rule: "Пояснение:\n\n1) -НН- в суффиксе причастия (при причастии есть зависимое слово);\n2) -Н- в кратком причастии;\n3) -Н- в кратком причастии;\n4) -НН- в существительном, образованном от прилагательного «лиственный»: -нн- сохраняется;\n5) -Н- в кратком причастии;\n6) -НН- в полном причастии."
    },
    {
      id: 12,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nСолнечный свет падал через высокое окно, лишё(1)ое гардины, на обои цвета топлё(2)ого молока — местами они потемнели, приобретя оттенок жжё(3)ой умбры, но сохранили грубую текстуру исти(4)о дорогих обоев позапрошлого века: всё тот же стари(5)ый рельеф «бурбонских лилий», благородная труха поблёскивающей в утре(6)ем солнце позолоты.",
      correctAnswer: "1456",
      rule: "Пояснение:\n\n1) -НН- в причастии;\n2) 3) -Н- в суффиксе отглагольного прилагательного;\n4) -НН- в наречии столько же -Н-, сколько в слове, от которого оно образовано: истинный;\n5) -НН- на стыке суффикса и корня: старин + -н-;\n6) -НН- в суффиксе -ЕНН-."
    },
    {
      id: 13,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nЗеленела трава, зеленели посадки в оголё(1)ых ещё огородах, и всё было так углубле(2)о в себя, так занято собою, так глубоко погруже(3)о в молчаливую творческую думу. Будь у травы и у деревьев лица, все лица были бы обраще(4)ы к земле, все уста были бы скова(5)ы огромным бездо(6)ым молчанием.",
      correctAnswer: "16",
      rule: "Пояснение:\n\n1) -НН- в причастии;\n2) 3) 4) 5) -Н- в кратких причастиях;\n6) -НН- в прилагательном, образованном от существительного с основой на -н: дно."
    },
    {
      id: 14,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nБыло безветре(1)о, пахло началом зимы, топта(2)ым листом клёна, талым снегом, свежеиспечё(3)ым ржа(4)ым хлебом; недавно краше(5)ые скамейки в парке добавляли в эту гамму тёплых ароматов химический запах масля(6)ой краски.",
      correctAnswer: "135",
      rule: "Пояснение:\n\n1) -НН- в суффиксе наречия, образованного от прилагательного с двумя буквами Н: безветренный → безветренно\n2) -Н- в суффиксе отглагольного прилагательного\n3) -НН- в суфиксе полного причастия\n4) -Н- в суффиксе -АН- прилагательного\n5) -НН- в суффиксе полного причастия\n6) -Н- в суффиксе -ЯН- прилагательного"
    },
    {
      id: 15,
      word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nТеплоход замер у подножия каменистого острова с полуразруше(1)ым монастырём по другую сторону, стари(2)ыми церковками и часовенками по опушкам и в чаще, мостиками через ручьи и овраги, с туристскими тропами и звери(3)ыми тропками, замер на грани двух прохлад – резкой озёрной и мягкой лесной, – со всей своей начинкой: хорошими и плохими людьми, жадными до впечатлений экскурсантами, растрога(4)ыми любителями природы, уставшими от города труже(5)иками, дисциплинирова(6)ой командой.",
      correctAnswer: "1246",
      rule: "Пояснение:\n\n1) -НН- в суффиксе причастия;\n2) -НН- в прилагательном на стыке корня и суффикса: старин+н;\n3) -Н- в суффиксе -ин-;\n4) -НН- в причастии;\n5) -Н- в суффиксе -ник- в существительном\n6) -НН- в слове с -ирова-."
    },
    {
      id: 16,
      word: "Указать все цифры, на месте которых пишется НН.\n\nКогда турист едет в промышле(1)ую державу, он рассчитывает, что её новые черты окажутся поисти(2)е живописными, экзотическими добавлениями к чертам традицио(3)ым, однако вместо этого приезжий видит самую неприглядную сторону совреме(4)ой цивилизации. Кажется, что хаос заводских труб, прокопчё(5)ых стен и железнодорожных путей похоронил под собой подли(6)ую культуру.",
      correctAnswer: "13456",
      rule: "Пояснение:\n\n1) две НН в суффиксе -ЕНН- прилагательного\n2) в наречии -Н-, т.к образовалось от существительного «истина», это наречие не на -о(-е)\n3) две НН в суффиксе -ОНН- прилагательного\n4) в суффиксе -ЕНН- прилагательного\n5) две НН в причастии\n6) Этимологически объяснить можно написание слова «подлинный»: подлинной в Древней Руси называлась та правда, которую подсудимый говорил «под длинниками» — особыми длинными палками или кнутами."
    },
    {
      id: 17,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nЧеловек остановился возле одноэтажного домика и прочитал слова над дверью, которые показались ему словами долгожда(1)ой телеграммы, написа(2)ой белыми буквами на синей стеклянной табличке: «Почта, телеграф, телефон». Однако в этот поздний час маленькие полукруглые оконца почты были уже заставле(3)ы карто(4)ыми щитками с категоричным словом «Закрыто».",
      correctAnswer: "124",
      rule: "Пояснение:\n\nдолгождаННой — слово-исключение из НН в причастиях;\nнаписаННой — полное страдательное причастие;\nзаставлеНы — Н в кратком причастии;\nкартоННыми — прилагательное от сущ. картон + Н."
    },
    {
      id: 18,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nКрылов рассея(1)о соглашался, и Женя понимала, что это его нисколько не занимает. Лисицкий окрестил Крылова снисходительно — «муче(2)ик науки», Алёша защищал его, но в последнее время о Крылове всё чаще говорили покровительстве(3)о, как о каком-то чудаке, обречё(4)ом, несчастливом.",
      correctAnswer: "134",
      rule: "Пояснение:\n\n1) в наречии столько же Н, сколько в слове, от которого оно образовано: рассеянный → рассеянно\n2) в существительном одна Н − запомнить\n3) в наречии столько же Н, сколько в слове, от которого оно образовано: покровительственный → покровительственно\n4) две НН в причастии"
    },
    {
      id: 19,
      word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nЧтоб не очень-то сквозило на давно заброше(1)ом сеновале, Данила зашил старыми ваго(2)ыми досками прорехи, щели в глубине деревя(3)ого сеновала; в общем-то, было и безветре(4)о, и покойно.",
      correctAnswer: "1234",
      rule: "Пояснение:\n\n1) -НН- в суффиксе причастия;\n2) -НН- в прилагательном на стыке корня и суффикса: вагон+н;\n3) -НН- слово−исключение;\n4) -НН- в суффиксе енн."
    },
    {
      id: 20,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nОкрыле(1)ый успехом своей поэмы, Парни издал сборник «Украде(2)ый портфель», куда вошла забавная пародия на поэму Мильтона «Потеря(3)ый рай». Но все же наиболее известными остаются его любовные элегии, в которых соедине(4)ы ирония и меланхолия, изящество и глубина.",
      correctAnswer: "123",
      rule: "Пояснение:\n\nВ этом предложении:\n\nокрылеННый — полное причастие, образованное от глагола совершенного вида;\n\nукрадеННый — полное причастие,образованное от глагола совершенного вида;\n\nпотеряННый — полное причастие, образованное от глагола совершенного вида;\n\nсоединеНы — краткое причастие пишем с Н."
    },
    {
      id: 21,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nИлья Андреич, весёлый, румя(1)ый, с трясущимися щеками, подкатил по зеленям к оставле(2)ому ему лазу, влез на свою лошадь и въехал в опушку кустов. Подле него стоял его камердинер, стари(3)ый, но отяжелевший ездок, Семён Чекмарь. Шагов на сто подальше стоял Митька, отчая(4)ый ездок и страстный охотник. Глаза Ильи Андреича особе(5)о блестели, и он, укута(6)ый в шубку, имел вид ребёнка, которого собрали гулять.",
      correctAnswer: "23456",
      rule: "Пояснение:\n\n1) -Н- в слове РУМЯНЫЙ надо запомнить\n2) -НН- в полном причастии\n3) -НН- на стыке морфем: старин+н\n4) -НН- в слове ОТЧАЯННЫЙ надо запомнить\n5) -НН- в суффиксе -ЕНН-\n6) -НН- полном причастии"
    },
    {
      id: 22,
      word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nОн молча слушал, облокотившись на лакирова(1)ый столик, и я со страхом ожидал признаков раздражения или прямой злости на его лице. Но его глаза были утомлё(2)о суже(3)ы, устремле(4)ы куда-то вдаль, будто он и впрямь видел над по-верхарновски чёрными фабричными трубами революцио(5)ый рассвет; и даже расслабле(6)ые пальцы руки его выражали глубокое огорчение, почти нескрываемую боль.",
      correctAnswer: "1256",
      rule: "Пояснение:\n\n1) -НН- в слове с суффиксом -ирова-;\n2) -НН- в наречии, образованном от прилагательного, в котором пишется -НН- (утомлённый → утомлённо);\n3) -Н- в кратком причастии;\n4) -Н- в кратком причастии;\n5) -НН- в суффиксе -онн-;\n6) -НН- в полном причастии."
    },
    {
      id: 23,
      word: "Укажите все цифры, на месте которых пишется НН.\n\nНавстречу ему поднимался поезд телег с ране(1)ыми во вчерашнем деле солдатами. Телеги, на которых лежали и сидели по три и по четыре ране(2)ые, прыгали по наброса(3)ым в виде мостовой камням на крутом подъёме. Солдаты, обвяза(4)ые тряпками, бледные, с поджатыми губами и нахмуре(5)ыми бровями, прыгали и толкались в телегах.",
      correctAnswer: "1345",
      rule: "Пояснение:\n\n1) -НН- в полном причастии (причастие, потому что есть зависимое слово)\n2) -Н- в отглагольном прилагательном надо запомнить\n3) -НН- в полном причастии\n4) -НН- в полном причастии\n5) -НН- в полном причастии"
    },
    {
      id: 24,
      word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nДину встретил весе(1)ий апрельский день – с остатками снега по обочинам дороги, с залепле(2)ыми дорожной слякотью машинами, с синим, в рва(3)ых облаках небом: обычный петербургский апрельский ветре(4)ый день.",
      correctAnswer: "12",
      rule: "Пояснение:\n\nветреный— прилагательное-исключение;\nрваНых— отглагольное прилагательное;\nвесеННий— прилагательное от слова весна;\nзалеплеННыми— полное страдательное причастие."
    },
    {
      id: 25,
      word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nЖизнь Пришвина – пример того, как человек отрешился от всего наносного, что навяза(1)о ему средой, и начал жить только по велению сердца, в согласии со своим внутре(2)им миром. Книги Пришвина – это бесконечная радость постоя(3)ых открытий, его проза наполне(4)а поэзией.",
      correctAnswer: "23",
      rule: "Пояснение:\n\n1. навязаНо — краткое причастие, Н;\n2. внутреННим— от сущ. нутр при помощи ЕНН;\n3. постояННых — исторически суффиксальное производное от постояти \"продолжаться, удерживаться\";\n4. наполнеНа — краткое причастие, Н."
    },
    {
          "id": 26,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nОн лично уже не нуждался в этих двугриве(1)ых и не хотел понять, что они составляют самую зако(2)ую собстве(3)ость бедных экспонентов, у которых вещи остаются чаще всего непрода(4)ыми и загромождают небольшие квартиры труже(5)иков, самозабве(6)о отдающихся своим идеям и отказывающихся от заказов в надежде кое-как перебиваться и существовать на выставочный дивиденд.",
          "correctAnswer": "12346",
          "rule": "Пояснение:\n\n1) Если в существительном две -нн- (гривенник), то и в прилагательном от него образованном две -нн-;\n2) -НН- на стыке морфем: закон + н;\n3) в суффиксе -ЕНН- две -нн-;\n4) -НН- в причастии;\n5) запомнить;\n6) в наречии столько же Н, сколько в слове, от которого оно образовано: самозабвенный → самозабвенно."
        },
        {
          "id": 27,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nВ образовавшийся провал панически высовывалась статуя — грубое, стари(1)ое изделие из некраше(2)ого дерева. На траве валялись груды каких-то сожжё(3)ых и затопта(4)ых бумаг, книжные переплёты, вытаще(5)ый наружу и разбитый о камни шкаф. Курт остановился, соображая. У него был намёта(6)ый глаз солдата, и он сразу почувствовал, что главный очаг пожарища должен быть, очевидно, версты за две отсюда — там, где находилась деревня Монтивер.",
          "correctAnswer": "13456",
          "rule": "Пояснение:\n\n1) -НН- на стыке морфем в прилагательном: старин+н;\n2) -Н- в отглагольном прилагательном;\n3) 4) 5) 6) -НН- в суффиксе полного причастия."
        },
        {
          "id": 28,
          "word": "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nСо временем Пётр привык к скудной пище, научился с удовольствием есть варё(1)ую картошку с постным маслом, а особенно полюбил свежевыпече(2)ый ржа(3)ой хлеб, от которого исходил несказа(4)о вкусный запах.",
          "correctAnswer": "24",
          "rule": "Пояснение:\n\n1) варёную — отглагольное прилагательное, от глагола несовершенного вида;\n2) свежевыпеченный— по образованию это причастие с суффиксом ЕНН, образовано от глагола «выпечь», именно этим объясняется две Н. А по значению это имя прилагательное;\n3) ржаной — прилагательное с суффиксом АН, корень рж;\n4) несказанно — наречие от несказанный."
        },
        {
          "id": 29,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nНа пепелищах старых имений рождаются такие милые женщины, всегда неопределё(1)ого возраста и необыкнове(2)ой доброты; никогда они не выходят замуж, берут на себя всевозможные тяготы, воспитывая удручё(3)ых племянников, и, воспитав, перебираются, всё в том же вяза(4)ом чёрном платье и с картонным футляром для очков, на новое пепелище, мечтая о прочита(5)ых книгах и всемирном добре.",
          "correctAnswer": "1235",
          "rule": "Пояснение:\n\n1) неопределёННого — причастие, поэтому суффикс -НН-;\n2) необыкновеННой — прилагательное с суффиксом -ЕНН-;\n3) удручёННых — причастие, поэтому суффикс -НН-;\n4) вязаНом — отглагольное прилагательное пишется с одной -Н-;\n5) прочитаННых — причастие, поэтому суффикс -НН-."
        },
        {
          "id": 30,
          "word": "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nДверь в комнату Лизы была отворе(1)а, и сама она сидела в старом плетё(2)ом кресле около постели, в капоте, укута(3)ая в шаль, непричёса(4)ая.",
          "correctAnswer": "34",
          "rule": "Пояснение:\n\n1) -Н- в суффиксе краткого причастия;\n2) -Н- в отглагольном прилагательном;\n3) -НН- в причастии;\n4) -НН- в причастии."
        },
        {
          "id": 31,
          "word": "Укажите все цифры, на месте которых пишется Н.\n\nСемейство кактусовых на да(1)ый момент — одна из самых изуче(2)ых и детально проработа(3)ых групп растений, но и сейчас учё(4)ые публикуют новые сенсацио(5)ые сообщения о формах, никем ра(6)ее не вида(7)ых и не описа(8)ых.",
          "correctAnswer": "46",
          "rule": "Пояснение:\n\n1) -НН- запомнить;\n2) -НН- в полном причастии;\n3) -НН- в полном причастии;\n4) -Н- в существительном «ученый»;\n5) -НН- в суффиксе -ОНН- отымённого прилагательного;\n6) -Н- в наречии в сравнительной степени, образовано от слова «рано»;\n7) -НН- запомнить;\n8) -НН- в полном причастии."
        },
        {
          "id": 32,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nНаконец только к вечеру ему удалось отыскать где-то под крышей среди груды пыльного хлама свои ящики. Очевидно, их перебрасывали с ребра на ребро, пока не засунули под тяжёлый, кова(1)ый железом сундук: планки были продавле(2)ы и местами оторва(3)ы, и хрупкие части его излюбле(4)ого, вогна(5)ого в тончайшее сплетение спиралей замысла раздробле(6)ы и убиты.",
          "correctAnswer": "145",
          "rule": "Пояснение:\n\n1) -НН- в полном причастии! ВНИМАНИЕ! слово «кованный» в данном предложении является причастием, потому что от него есть зависимое слово: кованный (чем?) железом!;\n2) -Н- в кратком причастии;\n3) -Н- в кратком причастии;\n4) -НН- в полном причастии;\n5) -НН- в полном причастии;\n6) -Н- в кратком причастии."
        },
        {
          "id": 33,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nКуинджи до невозможности утомлён был даже милыми, доброжелательными, преда(1)ейшими друзьями. А из этих, бывало, некоторые влетали к нему соверше(2)о расстрое(3)ые: забыть не могут — после бессо(4)ых ночей и созерцания — его картин. Не для красоты рассказа, исти(5)ая правда: были такие, которые на коленях умоляли автора уступить им какую-нибудь картину или незаконче(6)ый этюдик его работы.",
          "correctAnswer": "123456",
          "rule": "Пояснение:\n\n1) две НН сохраняется от «преданный»;\n2) в наречии столько же Н, сколько в слове, от которого оно образовано: совершенный → совершенно;\n3) две НН в полном причастии;\n4) в прилагательном на стыке морфем две НН: сон + н;\n5) в прилагательном на стыке морфем две НН: истин + н;\n6) две НН в причастии."
        },
        {
          "id": 34,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nСейчас в этом доме находится краеведческий центр, в котором хранятся работы из карельской соломки, выполне(1)ые Ольгой Соколовой с острова Кильпола. В отделе этнографии можно увидеть ткацкие станки, прялки и домотка(2)ые рушники, краше(3)ые травами. Но основная экспозиция посвяще(4)а лишайникам. Научная часть располагается как раз в той комнате, где учё(5)ый любил сидеть у камина.",
          "correctAnswer": "13",
          "rule": "Пояснение:\n\n1) -НН- в полном причастии;\n2) -Н- в отглагольном прилагательном;\n3) -НН- в полном причастии;\n4) -Н- в кратком причастии;\n5) -Н- в суффиксе существительного: в существительных, образованных от отглагольных прилагательных и от причастий, пишется столько -н-, сколько их было в прилагательном или причастии."
        },
        {
          "id": 35,
          "word": "Укажите все цифры, на месте которых пишется НН.\n\nУ пристани, протянувшейся бесчисле(1)ыми причалами, стояли, как будто набирали силы, гружё(2)ые корабли: они готовились отплыть в Швецию и Германию, и ветерок лениво полоскал серые, умышле(3)о приспуще(4)ые ветрила.",
          "correctAnswer": "134",
          "rule": "Пояснение:\n\n1) Бесчисленные — прилагательное с суффиксом -ЕНН-;\n2) Гружёные — отглагольное прилагательное, несов. вид, без приставки, Н;\n3) Умышленно — наречие, образованное от «умышленный», поэтому НН сохраняется;\n4) Приспущенные — причастие с суффиксом -НН-."
        },
        {
          id: 36,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется одна буква Н.\n\nВ Норвегии я с удовольствием испробовал приготовле(1)ую по местным рецептам рыбу: соле(2)ую в бочке, копчё(3)ую на еловых стружках, варё(4)ую, жаре(5)ую на гриле, паровую, печё(6)ую с мочё(7)ыми яблоками.",
          correctAnswer: "47",
          rule: "Пояснение:\n\n1) -НН- в полном причастии;\n2) -НН- в полном причастии;\n3) -НН- в полном причастии;\n4) -Н- в суффиксе отглагольного прилагательного;\n5) -НН- в полном причастии;\n6) -НН- в полном причастии;\n7) -Н- в суффиксе отглагольного прилагательного."
      },
      {
          id: 37,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется одна буква Н.\n\nРаньше он любил часами беседовать с Дашей: образова(1)ая, начита(2)ая, она восхищала недюжи(3)ым умом, романтическим цинизмом и подли(4)ым благородством; говорил он с нею рассея(5)о, пута(6)о.",
          correctAnswer: "6",
          rule: "Пояснение:\n\n1) -НН- в полном причастии;\n2) -НН- в полном причастии;\n3) -НН- на стыке морфем: дюжина+н;\n4) -НН- на стыке морфем: исторически происхождение слова «подлинный» связано со словом «длина»+н;\n5) -НН- в наречии, образованном от прилагательного «рассеянный», две НН сохраняются;\n6) -Н- в наречии, образованном от прилагательного «путаный»."
      },
      {
          id: 38,
          word: "Укажите все цифры, на месте которых пишется НН.\n\nНа половине дороги стояли сложе(1)ые дрова, а дорожка вела к амбару; рубле(2)ая стена амбара и заснеже(3)ая крыша, будто высече(4)ые из какого-то драгоце(5)ого камня, блестели в свете серебря(6)ого месяца.",
          correctAnswer: "1345",
          rule: "Пояснение:\n\n1), 3), 4) -НН- в полных причастиях.\nЗаснеженная — та, которую заснежили (что с нею сделали?). Страдательное причастие прошедшего времени, образованное от переходного глагола сов. вида «заснежить». Глагол на -ить, значит, добавлен суффикс ЕНН. Это не от слова снег...От слова снег — снежный.\n5) -НН- на стыке морфем в отымённом прилагательном, образованном от основы на -Н (цена) с помощью суффикса -Н-"
      },
      {
          id: 39,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nОтец у этого нищего был свяще(1)ик, а мать дворянка, значит, по рождению он принадлежал к привилегирова(2)ому сословию, но я всматривался в его лицо, в его рыжую, уже седеющую бороду, в рва(3)ый пиджак и никак не мог найти даже следа того, что у нас зовётся привилегиями. А он называл себя образова(4)ым и рассказывал, что учился в духовном училище.",
          correctAnswer: "124",
          rule: "Пояснение:\n\n1) -НН- в существительном;\n2) -НН- в прилагательном на -ованный;\n3) -Н- в прилагательном, образованном с помощью суффикса -н- от глагола несовершенного вида без приставки, при отсутствии зависимых слов;\n4) -НН- отглагольное прилагательное заканчивается на -ованный, -ёванный, пишется нн."
      },
      {
          id: 40,
          word: "Укажите все цифры, на месте которых пишется НН.\n\nБудяев был из числа тех всегда встревоже(1)ых людей, чья жизнь отравле(2)а переживанием будущих несчастий: глаза из-под нахмуре(3)ых бровей смотрели не насторожё(4)о даже, а обречё(5)о, и в них читалась увере(6)ость, что вот-вот должно случиться нечто непоправимое.",
          correctAnswer: "13456",
          rule: "Пояснение:\n\n1) -НН- в полном причастии;\n2) -Н- в кратком причастии;\n3) -НН- в полном причастии;\n4) 5) -НН- в наречии столько же -н-, сколько в слове, от которого оно образовано: настороженный, обречённый;\n6) -НН- в существительном"
      },
      {
          id: 41,
          word: "Укажите все цифры, на месте которых пишется НН.\n\nОни промчались мимо хлопковых полей, потом уже в городе долго кружили по узким улочкам, зажатым глиня(1)ыми заборами, пока где-то на окраи(2)ом строении не обнаружили написа(3)ую на фанере вывеску экспедиции. Во дворе стояли запылё(4)ые грузовики, на кошме в тени спал человек в замасле(5)ой майке и дли(6)ых шортах.",
          correctAnswer: "23456",
          rule: "Пояснение:\n\n1) -Н- в суффиксе -ЯН- отымённого прилагательного\n2) -НН- на стыке морфем в прилагательном: окраин + н\n3) 4) 5) -НН- в суффиксе полного причастия\n6) -НН- на стыке морфем: длин + н"
      },
      {
          id: 42,
          word: "Укажите все цифры, на месте которых пишется НН.\n\nРазгорячё(1)ый спором с Яшей, Лев, уже немного путаясь в собстве(2)ых суждениях, продолжал доказывать свою мысль о том, что, несомне(3)о, выводы Якова преждевреме(4)ы, они ничем не подкрепле(5)ы в реальной жизни.",
          correctAnswer: "1234",
          rule: "Пояснение:\n\n1) -НН- в полном причастии\n2) -НН- в суффиксе -ЕНН- отыменного прилагательного\n3) -НН- в суффиксе наречия, образованного от прилагательного, в котором две буквы Н: несомненный − несомненно\n4) -НН- в суффиксе -ЕНН- отыменного прилагательного\n5) -Н- в кратком причастии"
      },
      {
          id: 43,
          word: "Укажите все цифры, на месте которых пишется НН.\n\nКорабли, успевшие заблаговреме(1)о укрыться в гавани, были вытаще(2)ы на берег, но и сюда докатывались рассерже(3)ые волны, и тогда широко раскачивались и плясали стройные, точё(4)ые мачты, а доски скрипели, точно готовые развалиться.",
          correctAnswer: "13",
          rule: "Пояснение:\n\nЗаблаговременно- наречие, образованное от «заблаговременный», поэтому -НН- сохраняется.\nРассерженные — -НН- в суффиксе причастия.\nТочёные — отглагольное прилагательное (с одной Н). Вытащены — краткое причастие с одной -Н-."
      },
      {
          id: 44,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nХозяева дома явно торопились покинуть жилище: повсюду были разброса(1)ы оставле(2)ые вещи, на полу лежал перевёрнутый стари(3)ый кова(4)ый сундук.",
          correctAnswer: "23",
          rule: "Пояснение:\n\nразбросаНы— краткое причастие;\nоставлеННые— полное страдат. причастие;\nстариННый— прилагательное, образованное от слова старина+н;\nковаНый— отглагольное прилагательное, исключение."
      },
      {
          id: 45,
          word: "Укажите все цифры, на месте которых пишется НН.\n\nКняжна Марья видела смущё(1)ый и удивлё(2)ый взгляд Десаля, устремлё(3)ый на её отца, заметила его молчание и была пораже(4)а тем, что отец забыл письмо сына на столе в гости(5)ой.",
          correctAnswer: "123",
          rule: "Пояснение:\n\n1) -НН- в суффиксе -ЁНН-прилагательного\n2) -НН- в суффиксе -ЕНН- прилагательного\n3) -НН- в суфиксе полного причастия\n4) -Н- в кратком причастии\n5) -Н- в суффиксе -ИН- прилагательного"
      },
      {
          id: 46,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nОба, растрога(1)ые, пошли в дом и стали пить чай из стари(2)ых фарфоровых чашек, со сливками, с сытными сдобными свежеиспечё(3)ыми кренделями – и эти мелочи опять напомнили Коврину его детство и ю(4)ость.",
          correctAnswer: "123",
          rule: "Пояснение:\n\n1) -НН- в суффиксе причастия;\n2) -НН- в прилагательном на стыке корня и суффикса: старин +н;\n3) -НН- в суффиксе причастия;\n4) -Н- в корне: юн+ость;"
      },
      {
          id: 47,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nПочему Бах, потомстве(1)ый музыкант из старого, почте(2)ого, всюду уважаемого рода, стоявшего много выше рода Швальбе, оказался неотёса(3)ым мужланом, синдик и сам не знал. Он, правда, слышал, что Бах, человек просвещё(4)ый и начита(5)ый, не получил университетского образования в отличие от большинства известных музыкантов, но сам Швальбе не кончил даже гимназии и ставил это себе в заслугу, почитая самообразование выше готовых знаний.",
          correctAnswer: "12345",
          rule: "Пояснение:\n\n1) -НН- в суффиксе -ЕНН- отымённого прилагательного;\n2) -НН- на стыке морфем: почтение + -н--;\n3) -НН- в полном причастии;\n4) -НН- пишется в прилагательных и причастиях, образованных от глаголов совершенного вида, обозначающих завершенное действие;\n5) -НН- в полном причастии."
      },
      {
          id: 48,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nНа озерце, глубоко запрята(1)ом в крутых берегах, лежала зелё(2)ая илистая тень, и в этой тени сверкали серебря(3)ыми огоньками ракиты, которые, словно алмазами, были усыпа(4)ы каплями росы.",
          correctAnswer: "1",
          rule: "Пояснение:\n\nзапрятаННом — полное причастие;\nзелёНая — это корень;\nсеребряНыми — суффикс АН/ЯН прилагательного;\nусыпаНы — краткое причастие."
      },
      {
          id: 49,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nПодготовле(1)ую и хорошо посоле(2)ую внутри и снаружи тушку кладут в кастрюлю, заливают топлё(3)ым сви(4)ым жиром, обкладывают кусочками копчё(5)ого сала, добавляют 4−5 морковок, нареза(6)ых кубиками, головку измельчё(7)ого лука, чайную ложечку толчё(8)ого или молотого красного перца и жарят час.",
          correctAnswer: "1267",
          rule: "Пояснение:\n\n1) -НН- в полном причастии;\n2) -НН- в полном причастии;\n3) -Н- в отглагольном прилагательном;\n4) -Н- в притяжательных прилагательных, образованных от сущ. на Н, вторая Н не добавляется: свиной, тюлений, фазаний, бараний и др.\n5) -Н- в отглагольном прилагательном;\n6) -НН- в полном причастии;\n7) -НН- в полном причастии;\n8) -Н- в отглагольном прилагательном."
      },
      {
          id: 50,
          word: "Укажите цифру(-ы), на месте которой(-ых) пишется НН.\n\nМолодая жаре(1)ая картошка была щедро посыпа(2)а свежим луком и укропом, приправле(3)а смета(4)ым соусом и казалась нам самым изыска(5)ым деликатесом.",
          correctAnswer: "45",
          rule: "Пояснение:\n\n1) -Н- в отглагольном прилагательном\n2) -Н- в кратком причастии\n3) -Н- в кратком причастии\n4) -НН- на стыке морфем: сметан + н\n5) -НН- в суффиксе прилагательного, образованного от приставочного глагола совершенного вида"
      }
      
 ]