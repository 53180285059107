// StartScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StartScreen = ({ onContinue, onNewStart, hasProgress }) => {
  const navigate = useNavigate();

  return (
    <div className="start-screen">
      <div className="start-screen-container">
        <h1>Тренажер Н/НН</h1>
        <div className="start-screen-buttons">
          {hasProgress && (
            <>
              <button 
                onClick={onContinue}
                className="continue-btn"
              >
                Продолжить
              </button>
              <button 
                onClick={onNewStart}
                className="new-start-btn"
              >
                Начать заново
              </button>
            </>
          )}
          {!hasProgress && (
            <button 
              onClick={onNewStart}
              className="new-start-btn"
            >
              Начать
            </button>
          )}
          <button 
            onClick={() => navigate('/')} 
            className="btn-menu"
          >
            Вернуться в меню
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;