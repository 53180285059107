// CelebrationScreen.js
import React, { useState, useEffect } from 'react';

const CelebrationScreen = ({ onClose }) => {
  const [_showMessage, setShowMessage] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => setShowMessage(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const generateFireworks = () => {
    return Array.from({ length: 12 }).map((_, i) => (
      <div 
        key={i} 
        className="firework"
        style={{
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          animationDelay: `${Math.random() * 2}s`
        }}
      />
    ));
  };

  return (
    <div className="celebration-container">
      <div className="relative w-full h-full overflow-hidden">
        {generateFireworks()}
        
        <div className="celebration-content">
          <h2 className="celebration-title">
            Поздравляю тебя, мой друг!
          </h2>
          
          <p className="celebration-message">
            Ты сделал это! Я верю, что Ты напишешь ЕГЭ по русскому языку на желаемый балл и поступишь в ВУЗ своей мечты, просто работай над собой и верь в себя, УДАЧИ!
          </p>
          
          <button
            onClick={onClose}
            className="celebration-button"
          >
            Вернуться в меню
          </button>
        </div>
      </div>
    </div>
  );
};

export default CelebrationScreen;