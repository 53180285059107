import React, { useEffect, useRef, useState } from 'react';
import { Check, X } from 'lucide-react';

const EGEWordCard = ({
  word,
  userAnswer,
  onAnswerChange,
  onCheckAnswer,
  onNextWord,
  isAnswerChecked,
  isAnswerCorrect,
  showCorrectAnswer,
  taskId
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isFocused && inputRef.current) {
      // Добавляем небольшую задержку для iOS
      setTimeout(() => {
        const inputRect = inputRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const keyboardHeight = viewportHeight * 0.4; // Примерная высота клавиатуры
        const desiredSpace = viewportHeight * 0.15; // Желаемое расстояние от поля до клавиатуры
        
        const targetPosition = viewportHeight - keyboardHeight - desiredSpace;
        const currentPosition = inputRect.top;
        const scrollAdjustment = currentPosition - targetPosition;
        
        if (scrollAdjustment > 0) {
          window.scrollTo({
            top: window.pageYOffset + scrollAdjustment,
            behavior: 'smooth'
          });
        }
      }, 100);
    }
  }, [isFocused]);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const handleInputChange = (e) => {
    const filteredValue = e.target.value.replace(/[^0-9]/g, '');
    onAnswerChange(filteredValue);
  };

  return (
    <div 
      className="ege-card" 
      ref={containerRef}
    >
      <div className="task-number">
        Задание {taskId}
      </div>
      
      <div className="task-text">
        {word.word}
      </div>
      
      <div className="answer-section">
        <div className="input-group">
          <input
            ref={inputRef}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={userAnswer}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            placeholder="Введите цифры"
            disabled={isAnswerChecked}
            className={`answer-input ${
              isAnswerChecked ? (isAnswerCorrect ? 'correct' : 'incorrect') : ''
            }`}
            autoComplete="off"
          />
          
          {isAnswerChecked && (
            <div 
              className={`answer-indicator ${
                isAnswerCorrect ? 'correct' : 'incorrect'
              }`}
            >
              {isAnswerCorrect ? <Check size={20} /> : <X size={20} />}
            </div>
          )}
        </div>
        
        {!isAnswerChecked && (
          <button 
            className="btn-check"
            onClick={onCheckAnswer}
            disabled={!userAnswer.trim()}
            type="button"
          >
            Проверить
          </button>
        )}
      </div>

      {isAnswerChecked && (
        <div className="feedback-section">
          <div className="answer-text">
            Ответ: {word.correctAnswer}
          </div>
          <div className="rule-explanation">
            {word.rule}
          </div>
          <button 
            className="btn-next" 
            onClick={onNextWord}
            type="button"
          >
            Продолжить
          </button>
        </div>
      )}
    </div>
  );
};

export default EGEWordCard;