import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';

const Heart = ({ filled }) => (
  <svg 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill={filled ? "#ff4081" : "none"}
    stroke={filled ? "none" : "#e0e0e0"}
    strokeWidth="2"
    className="heart-icon"
  >
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
  </svg>
);

const WordCard = ({ 
  word, 
  fullWord, 
  correctAnswer, 
  rule, 
  correctAttempts,
  currentWordIndex,
  totalWords, 
  completedWords,
  onAnswer, 
  onNextWord 
}) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [isDisappearing, setIsDisappearing] = useState(false);
  const [isRuleExpanded, setIsRuleExpanded] = useState(false);
  
  const progress = (completedWords / totalWords) * 100;

  useEffect(() => {
    setShowAnswer(false);
    setIsCorrect(null);
    setShowCheckmark(false);
    setIsDisappearing(false);
    setIsRuleExpanded(false);
  }, [word]);

  const handleSubmit = (answer) => {
    const correct = answer.toLowerCase() === correctAnswer.toLowerCase();
    setIsCorrect(correct);
    setShowAnswer(true);
    onAnswer(answer, correct);

    if (correct && correctAttempts === 2) {
      setShowCheckmark(true);
      setTimeout(() => {
        setIsDisappearing(true);
        setTimeout(() => {
          setShowCheckmark(false);
          onNextWord();
        }, 450);
      }, 900);
    }
  };

  const handleNext = () => {
    setShowAnswer(false);
    setIsCorrect(null);
    onNextWord();
  };

  const hearts = [0, 1, 2].map(i => (
    <span key={i} className="heart-container">
      <Heart filled={i < correctAttempts} />
    </span>
  ));

  const truncateRule = (text) => {
    if (text.length <= 100) return text;
    const words = text.split(' ');
    return words.slice(0, 10).join(' ');
  };

  const shouldTruncate = rule.length > 100;

  const renderRule = () => {
    if (!shouldTruncate) return rule;
    
    if (!isRuleExpanded) {
      return (
        <>
          <span>{truncateRule(rule)}... </span>
          <span 
            className="rule-toggle"
            onClick={() => setIsRuleExpanded(true)}
          >
            Развернуть
          </span>
        </>
      );
    }
    
    return (
      <>
        <span>{rule} </span>
        <span 
          className="rule-toggle"
          onClick={() => setIsRuleExpanded(false)}
        >
          Свернуть
        </span>
      </>
    );
  };

  return (
    <div className="training-container">
      <ProgressBar progress={progress} />
      <div 
        className={`word-card ${isCorrect === true ? 'correct' : ''} ${isCorrect === false ? 'incorrect' : ''} ${isDisappearing ? 'disappearing' : ''}`}
      >
        <div className="hearts-container">{hearts}</div>
        <div className="word-content">
          {showCheckmark && (
            <div className={`checkmark-overlay ${isDisappearing ? 'disappearing' : ''}`}>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          )}
          <h2 className="word-title">{word}</h2>
          
          {!showAnswer ? (
            <div className="word-controls">
              <div className="btn-group">
                <button 
                  className="btn btn-primary btn-large"
                  onClick={() => handleSubmit('н')}
                >
                  Н
                </button>
                <button 
                  className="btn btn-primary btn-large"
                  onClick={() => handleSubmit('нн')}
                >
                  НН
                </button>
              </div>
            </div>
          ) : (
            <div className="answer-section">
              <div className="answer-result">
                {isCorrect ? 'Правильно!' : 'Неправильно!'}
              </div>
              <div className="correct-answer">
                Правильный ответ: {fullWord}
              </div>
              <div className="rule-explanation">
                {renderRule()}
              </div>
              <button 
                className="btn btn-primary btn-large next-word-btn"
                onClick={handleNext}
              >
                Следующее слово
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WordCard;