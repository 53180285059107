import React from 'react';
import { Link } from 'react-router-dom';
import SendMailForm from './SendMailForm';

function Menu() {
  return (
    <div className="menu">
      <div className="title-first">Тренажер по русскому языку</div>
      <div className="title-second">Задание 15 ЕГЭ</div>
      <div className="menu-list" style={{ position: 'relative', minHeight: '500px' }}>
        <div className="top-buttons">
          <Link to="/nnn-training" className="menu-link">
            <div className="menu-icon">✏️</div>
            <h3>Тренажер Н/НН</h3>
            <p>Тренируйте правописание Н и НН в разных частях речи</p>
          </Link>
          <Link to="/rules" className="menu-link">
            <div className="menu-icon">📒</div>
            <h3>Правила</h3>
            <p>Изучите правила написания Н и НН в разных частях речи</p>
          </Link>
        </div>
        <div className="bottom-button">
          <Link to="/exam-tasks" className="menu-link full-width">
            <div className="menu-icon">📝</div>
            <h3>Задания как в ЕГЭ</h3>
            <p>Тренируйтесь на подобных заданиях как в ЕГЭ</p>
          </Link>
        </div>
        <SendMailForm />
      </div>
    </div>
  );
}

export default Menu;