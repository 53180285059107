// EGEStartScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const EGEStartScreen = ({ onContinue, onNewStart, hasProgress }) => {
  const navigate = useNavigate();

  return (
    <div className="start-screen">
      <div className="start-screen-container">
        <h1>Задания как в ЕГЭ</h1>
        <div className="start-screen-buttons">
          <button 
            onClick={onContinue}
            className="continue-btn"
          >
            Продолжить
          </button>
          <button 
            onClick={onNewStart}
            className="new-start-btn"
          >
            Начать заново
          </button>
          <button 
            onClick={() => navigate('/')} 
            className="btn-menu"
          >
            Вернуться в меню
          </button>
        </div>
      </div>
    </div>
  );
};

export default EGEStartScreen;