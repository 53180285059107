import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { egeWords } from '../data/egeWords';
import EGEWordCard from './EGEWordCard';
import EGEStartScreen from './EGEStartScreen';
import CelebScreenEGE from './CelebScreenEGE';
import '../App.css';

const STORAGE_KEY = 'ege-training-progress';
const REPEAT_WINDOW = 15;

const EGESection = () => {
  const navigate = useNavigate();
  const [words, setWords] = useState([]);
  const [wordQueue, setWordQueue] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [progress, setProgress] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasProgress, setHasProgress] = useState(false);
  const [showMainContent, setShowMainContent] = useState(false);
  const totalTasks = egeWords.length;
  const remainingTasks = wordQueue.length;
  const completedTasks = totalTasks - remainingTasks;
  const progressPercentage = Math.round((completedTasks / totalTasks) * 100);
  const [_allTasksCompleted, _setAllTasksCompleted] = useState(false);

  useEffect(() => {
    const savedProgress = localStorage.getItem(STORAGE_KEY);
    if (savedProgress) {
      const parsedProgress = JSON.parse(savedProgress);
      const hasExistingProgress = Object.keys(parsedProgress.progress || {}).length > 0;
      setHasProgress(hasExistingProgress);
      
      if (hasExistingProgress) {
        const { words: savedWords, wordQueue: savedQueue, progress: savedProgressData } = parsedProgress;
        setWords(savedWords);
        setWordQueue(savedQueue);
        setProgress(savedProgressData);
      } else {
        initializeNewSession();
        setShowMainContent(true);
      }
    } else {
      setHasProgress(false);
      initializeNewSession();
      setShowMainContent(true);
    }
    setIsLoading(false);
  }, []);

  const initializeNewSession = () => {
    const initialWords = egeWords.map(word => ({
      ...word,
      correctAttempts: 0,
      totalAttempts: 0
    }));
    setWords(initialWords);
    setWordQueue([...Array(initialWords.length).keys()]);
    setProgress({});
  };

  const handleContinue = () => {
    setShowMainContent(true);
  };

  const handleNewStart = () => {
    localStorage.removeItem(STORAGE_KEY);
    initializeNewSession();
    setCurrentIndex(0);
    setHasProgress(false);
    setShowMainContent(true);
  };

  useEffect(() => {
    if (!isLoading && showMainContent) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({
        words,
        wordQueue,
        progress
      }));
    }
  }, [words, wordQueue, progress, isLoading, showMainContent]);

  const checkAnswer = useCallback(() => {
    if (!userAnswer.trim()) return;

    const _currentWord = words[wordQueue[currentIndex]];
    const userNumbers = userAnswer.split('').sort().join('');
    const correctNumbers = _currentWord.correctAnswer.split('').sort().join('');
    const isCorrect = userNumbers === correctNumbers;

    setIsAnswerCorrect(isCorrect);
    setIsAnswerChecked(true);

    setWords(prevWords => {
      const newWords = [...prevWords];
      const wordIndex = wordQueue[currentIndex];
      newWords[wordIndex] = {
        ...newWords[wordIndex],
        correctAttempts: isCorrect ? newWords[wordIndex].correctAttempts + 1 : newWords[wordIndex].correctAttempts,
        totalAttempts: newWords[wordIndex].totalAttempts + 1
      };
      return newWords;
    });

    setProgress(prev => ({
      ...prev,
      [currentWord.id]: {
        attempts: (prev[currentWord.id]?.attempts || 0) + 1,
        correct: (prev[currentWord.id]?.correct || 0) + (isCorrect ? 1 : 0),
        timestamp: Date.now()
      }
    }));
  }, [currentIndex, userAnswer, words, wordQueue]);

  const handleNextWord = useCallback(() => {
    const currentWord = words[wordQueue[currentIndex]];
    
    setWordQueue(prevQueue => {
      let newQueue = [...prevQueue];
      
      if (!isAnswerCorrect) {
        const wordToRepeat = newQueue[currentIndex];
        newQueue = newQueue.filter((_, index) => index !== currentIndex);
        const insertPosition = Math.min(currentIndex + REPEAT_WINDOW, newQueue.length);
        newQueue.splice(insertPosition, 0, wordToRepeat);
      } else {
        newQueue = newQueue.filter((_, index) => index !== currentIndex);
      }
      
      return newQueue;
    });

    setCurrentIndex(0);
    setUserAnswer('');
    setIsAnswerChecked(false);
    setIsAnswerCorrect(false);
  }, [currentIndex, isAnswerCorrect, words, wordQueue]);

  if (isLoading) {
    return <div className="ege-loading">Загрузка...</div>;
  }

  if (hasProgress && !showMainContent) {
    return (
      <EGEStartScreen 
        onContinue={handleContinue}
        onNewStart={handleNewStart}
        hasProgress={hasProgress}
      />
    );
  }

  const currentWord = words[wordQueue[currentIndex]];

  if (!currentWord) {
    return <CelebScreenEGE 
      onNavigateHome={() => navigate('/')} 
      allTasksCompleted={true} 
    />;
  }

  const recentProgress = Object.entries(progress)
    .sort(([, a], [, b]) => (b.timestamp || 0) - (a.timestamp || 0))
    .slice(0, 3);

  return (
    <div className="ege-section">
      <h2>Задания ЕГЭ</h2>
      
      <div className="ege-tasks-progress">
        <div className="ege-progress-stats">
          <div className="ege-stats-item">
            <span className="ege-stats-label">Выполнено:</span>
            <span className="ege-stats-value">{completedTasks} из {totalTasks}</span>
          </div>
        </div>
        <div className="ege-progress-bar-container">
          <div 
            className="ege-progress-bar" 
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      </div>
      
      <EGEWordCard
        word={currentWord}
        userAnswer={userAnswer}
        onAnswerChange={setUserAnswer}
        onCheckAnswer={checkAnswer}
        onNextWord={handleNextWord}
        isAnswerChecked={isAnswerChecked}
        isAnswerCorrect={isAnswerCorrect}
        taskId={currentWord.id}
      />

      <div className="ege-progress-container">
        <h2 className="ege-progress-title">Ваш прогресс</h2>
        <div className="ege-progress-list">
          {recentProgress.map(([wordId, stats]) => (
            <div 
              key={wordId} 
              className={`ege-progress-item ${stats.correct > 0 ? 'correct' : 'incorrect'}`}
            >
              <span className="ege-progress-word">
                Задание {wordId}
              </span>
              <span className={`ege-progress-indicator ${stats.correct > 0 ? 'correct' : 'incorrect'}`}>
                {stats.correct}/{stats.attempts}
              </span>
            </div>
          ))}
          {Object.keys(progress).length === 0 && (
            <div className="ege-progress-empty">
              Пока нет выполненных заданий
            </div>
          )}
        </div>
      </div>
      
      <button onClick={() => navigate('/')} className="ege-btn-menu">
        Вернуться в меню
      </button>
    </div>
  );
}

export default EGESection;